import React,{Component, Fragment} from "react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
// import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Tabs } from "@aws-amplify/ui-react";
import { TabItem } from "@aws-amplify/ui-react";

import LoginButton from "./components/LoginButton";
import LogoutButton from "./components/LogoutButton";
import Profile from "./components/Profile";


import Header from "./pages/Header/Header";
import Footer from "./pages/Footer/Footer";

import DndLearn from "./pages/DndLearn/DndLearn";

import Home from "./pages/Home/Home";
import Define from "./pages/Define/Define";
import Research from "./pages/Research/Research";
import Result from "./pages/Result/Result";
import DevTools from "./pages/DevTools/DevTools";
import D3Learn from "./pages/D3Learn/D3Learn";
import Iot from "./pages/Iot/Iot";

// import GoLearn2 from "./pages/GoLearn/GoLearn2";


// Configure Amplify in index file or root file
Amplify.configure({
    Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.USER_POOL_ID,
        userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
    }
})

class App extends Component{
    render(){
        return(
            // <Authenticator>
            //     {
            //         ({signOut,user})=>(
            //             <div>
            //                 <Can/>
            //                 <button onClick={signOut}>signOut</button>
            //             </div>
            //         )
            //     }
            // </Authenticator>
            <Fragment>
                <Header/>
                <LoginButton/>
                <LogoutButton/>
                <Profile/>
                <h1 className="text-5xl bg-yellow-500">Test tailwindcss</h1>


                {/* <Tabs justifyContent="flex-start">

                    <TabItem title="Home">
                        <Home/>
                    </TabItem>

                    <TabItem title="Define">
                        <Define/>
                    </TabItem>

                    <TabItem title="Research">
                        <Research/>
                    </TabItem>

                    <TabItem title="Result">
                        <Result/>
                    </TabItem>

                    <TabItem title="D3">
                        <D3Learn></D3Learn>
                    </TabItem>


                </Tabs> */}
                <Footer/>
            </Fragment>
 
        )
    }
}

export default App;