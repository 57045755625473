import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.css"


const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(

    <Auth0Provider
        domain="dev-xpiuw7w4ibssjy0m.us.auth0.com"
        clientId="zkBdUl4Pt6MSuidbh8J5oNy7snSlat9p"
        authorizationParams={{
            redirect_uri: window.location.origin
        }}
    >
        <App/>
    </Auth0Provider>,

    document.getElementById("root")
);


