/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:53264b86-b79c-41c4-a96d-d64dcf933e72",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_ONNgGMLSo",
    "aws_user_pools_web_client_id": "33079b8ol24v4730a43u5embtn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "apitest",
            "endpoint": "https://hvac9tqa8k.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        }
    ],
    "REGION": "ap-northeast-1",
    "USER_POOL_ID": "ap-northeast-1_Wzz0zmexi",
    "USER_POOL_APP_CLIENT_ID": "2iho1raf6t92o9r6ldbi773r9s"
};


export default awsmobile;
